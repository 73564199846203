/* Home.css */

.home-container {
    position: relative;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .video-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  
  .home-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video covers the whole container */
  }
  
  .home-content {
    position: relative;
    color: rgb(15, 52, 173);
    z-index: 1;
    text-align: center;
  }
  
  h1 {
    font-size: 3rem;
  }
  
  p {
    font-size: 1.2rem;
  }
  /* Media query for mobile screens (up to 768px width) */
@media screen and (max-width: 768px) {
  .footer-container {
    padding: 15px;
    font-size: 14px; /* Smaller font size for mobile */
  }
  
  .main-content {
    padding: 20px; /* Adjust padding to avoid footer overlap */
  }
}