.about-section {
    
}

.about-profile-pic {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
  }

.container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
}

h2 {
    text-align: left;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: rgb(15, 52, 173);
}

p {
    font-size: 1.2rem;
    line-height: 1.6;
    margin-bottom: 20px;
}
/* Media query for mobile screens (up to 768px width) */
@media screen and (max-width: 768px) {
    .footer-container {
      padding: 15px;
      font-size: 14px; /* Smaller font size for mobile */
    }
    
    .main-content {
      padding: 20px; /* Adjust padding to avoid footer overlap */
    }
  }