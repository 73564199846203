/* Footer.css */
.footer {
    background-image: linear-gradient(90deg, #3b71db, #61dafb); /* Gradient background */
    padding: 5px 0;
    text-align: center;
    color: white;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .footer a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
    font-weight: 500;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer p {
    margin: 5px 0;
    font-size: 14px;
  }
  /* Media query for mobile screens (up to 768px width) */
@media screen and (max-width: 768px) {
  .footer-container {
    padding: 15px;
    font-size: 14px; /* Smaller font size for mobile */
  }
  
  .main-content {
    padding: 20px; /* Adjust padding to avoid footer overlap */
  }
}